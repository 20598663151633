import * as React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { graphql } from "gatsby";
import { Grid, Paper } from "@material-ui/core";
import { Layout } from "../common/Layout";
import { SEO } from "../components/seo";
import { Heading } from "../components/heading";
import { useUIState } from "../app/uiContext";

// Query to be run on build, passes resulting JSON as 'data' prop
export const query = graphql`
    {
        allDirectusNews(sort: { fields: [created_on], order: DESC }) {
            nodes {
                body
                created_on
                directusId
                modified_by
                modified_on
                status
                summary
                title
                url_title
            }
        }
    }
`;

const News = ({ data }) => {
    const { userLocale } = useUIState();
    //const formattedDate = new Intl.DateTimeFormat(userLocale, {year: `numeric`, month: `long`}).format(new Date(news.created_on));
    return (
        <Layout>
            <SEO
                description={``}
                keywords={[
                    `news`,
                    `ccg`,
                    `collectible`,
                    `card`,
                    `game`,
                    `trade`,
                ]}
                title={`CCG Trader Latest News`}
            />
            <section className="">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Heading text="News" />
                    </Grid>
                    {data.allDirectusNews.nodes.map((news) => (
                        <Grid item key={news.directusId} xs={12}>
                            <Paper className="p-4">
                                <h2 className="text-xl">{news.title}</h2>
                                <p className="mb-4">
                                    {new Intl.DateTimeFormat(userLocale).format(
                                        new Date(news.created_on)
                                    )}
                                </p>
                                <ReactMarkdown
                                    children={news.body}
                                    className="markdown"
                                    remarkPlugins={[remarkGfm]}
                                />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </section>
        </Layout>
    );
};

export default News;
